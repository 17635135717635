import angular from 'angular';
import app from 'app';
import { mnLoadNgModule } from 'mn.app.imports';

import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

angular
  .module(app)
  .config((mnPluggableUiRegistryProvider, mnPermissionsProvider) => {
    mnPermissionsProvider.set('cluster.admin.internal!all');
    mnPluggableUiRegistryProvider.registerConfig({
      name: 'Backup',
      state: 'app.admin.backup',
      plugIn: 'adminTab',
      ngShow: 'rbac.cluster.backup.all',
      after: 'buckets',
      responsiveHide: true,
    });
  });

class BackupUI {
  static get annotations() {
    return [
      new NgModule({
        imports: [
          UIRouterUpgradeModule.forRoot({
            states: [{
              name: 'app.admin.backup.**',
              url: '/backup',
              lazyLoad: mnLoadNgModule(() => import('./backup.js'), 'BackupModule'),
            }],
          }),
        ],
      }),
    ];
  }
}

export default BackupUI;
